<template>
  <div class="calendar">
    <img src="~img/35.png" class="bg-img">
    <div class="calendar_box">
      <div class="calendar-top">
        <van-icon name="arrow-left" @click="lastMonth" />
        <div class="select" @click="showSeleCalendar">
          <p>{{common.convertToChineseNumeral(currentMonth)}}月 {{currentYear}}</p>
          <van-icon name="arrow-down" color="#C4C4C4" />
        </div>
        <van-icon name="arrow" @click="nextMonth" />
      </div>
      <van-calendar ref="calendar" @select='selectchangeDate' :show-title='false' :show-subtitle='false'
        :formatter='formatter' :min-date="minDate" :max-date="maxDate" :poppable="false" :show-confirm="false"
        row-height='55' :style="{ height: '350px' }" />
    </div>
    <div class="activity_lis">
      <div class="title">
        <div class="icon-box"></div>
        <p>当天活动</p>
      </div>
      <ul>
        <li v-for="item in list" :key="item.id" @click="toActivityInfo(item)">
          <div class="top_box">
            <div class="yuan"></div>
            <p class="p1" v-if="item.label.indexOf('enroll_time') !== -1">
              {{item.enroll_start_time}}-{{item.enroll_end_time}}</p>
          </div>
          <p class="p2">{{item.address}}{{item.name}}公益活动
            <span v-if="item.type === 1">（联想活动）</span>
            <span v-else>（外部活动）</span>
          </p>
        </li>

      </ul>
    </div>
    <!-- 弹出 -->
    <div class="calendar-popup" @click="closePopup" v-show="showPopover">
      <van-picker :show-toolbar='false' :columns="columns" @change='changeDate'></van-picker>
    </div>
    <tabbar />
  </div>
</template>


<script>
import tabbar from "../components/Tabbar.vue";
import wxShare from "../utils/wxShare.js";

export default {
  components: {
    tabbar,
  },
  data() {
    return {
      showPopover: false,
      columns: [
        // 第一列
        {
          values: ["2023", "2022", "2021", "2020", "2019"],
          defaultIndex: 0,
        },
        // 第二列
        {
          values: [
            "一月",
            "二月",
            "三月",
            "四月",
            "五月",
            "六月",
            "七月",
            "八月",
            "九月",
            "十月",
            "十一月",
            "十二月",
          ],
          defaultIndex: 0,
        },
      ],
      currentYear: new Date().getFullYear(), // 当前年份
      currentMonth: new Date().getMonth() + 1,
      currentDate: new Date().getDate(),
      minDate: null,
      maxDate: null,
      activityDate: undefined,
      activityList: [],
      selectDate: "",
      list: [],
    };
  },
  created() {
    this.activityDate = this.moment(new Date()).format("YYYY-MM");
    this.selectDate = this.moment(new Date()).format("YYYY-MM-DD");
    this.minDate = new Date(this.currentYear, this.currentMonth - 1, 1);
    this.maxDate = new Date(this.currentYear, this.currentMonth - 1, 31);
    this.getActivityCalendar(); // 报500
    this.getActivity();
    console.log(this.currentYear, "-", this.currentMonth, this.currentDate);
  },
  mounted() {
    wxShare.commShare("calendar");
  },
  methods: {
    formatter(day) {
      if (day.date != undefined) {
        const month = day.date.getMonth() + 1;
        const date = day.date.getDate();
        if (this.activityList.length > 0) {
          if (month === this.currentMonth) {
            this.activityList.forEach((item) => {
              if (date == item) {
                day.bottomInfo = "•";
              }
            });
          }
        }
      }

      return day;
    },
    showSeleCalendar() {
      this.showPopover = true;
      this.columns[0].values = [
        this.currentYear - 1,
        this.currentYear,
        this.currentYear + 1,
        this.currentYear + 2,
        this.currentYear + 3,
      ];
      this.columns[0].defaultIndex = 1;
      this.columns[1].defaultIndex = this.currentMonth - 1;
    },
    closePopup() {
      console.log("关闭");
      this.showPopover = false;
      const days = new Date(this.currentYear, this.currentMonth, 0).getDate();
      this.minDate = new Date(this.currentYear, this.currentMonth - 1, 1);
      this.maxDate = new Date(this.currentYear, this.currentMonth - 1, days);
      this.$refs.calendar.scrollToDate(
        new Date(this.currentYear, this.currentMonth - 1)
      );
      this.activityDate = this.currentYear + "-" + this.currentMonth;
      this.getActivityCalendar();
    },
    changeDate(picker, values) {
      console.log(picker, values);
      let month = values[1];
      console.log(month);
      let index = this.columns[1].values.indexOf(month);
      this.currentYear = values[0];
      this.currentMonth = index + 1;
    },

    // 上个月
    lastMonth() {
      this.currentMonth--;
      if (this.currentMonth == 0) {
        this.currentYear--;
        this.currentMonth = 12;
      }
      const days = new Date(this.currentYear, this.currentMonth, 0).getDate();
      this.minDate = new Date(this.currentYear, this.currentMonth - 1, 1);
      this.maxDate = new Date(this.currentYear, this.currentMonth - 1, days);
      this.$refs.calendar.scrollToDate(
        new Date(this.currentYear, this.currentMonth - 1)
      );
      this.activityDate = this.currentYear + "-" + this.currentMonth;
      console.log(this.activityDate);
      this.getActivityCalendar();
    },
    // 下个月
    nextMonth() {
      this.currentMonth++;
      if (this.currentMonth > 12) {
        this.currentYear++;
        this.currentMonth = 1;
      }
      const days = new Date(this.currentYear, this.currentMonth, 0).getDate();
      this.minDate = new Date(this.currentYear, this.currentMonth - 1, 1);
      this.maxDate = new Date(this.currentYear, this.currentMonth - 1, days);
      this.$refs.calendar.scrollToDate(
        new Date(this.currentYear, this.currentMonth - 1)
      );
      this.activityDate = this.currentYear + "-" + this.currentMonth;
      console.log(this.activityDate);
      this.getActivityCalendar();
    },

    async getActivityCalendar() {
      const res = await this.axios.get(
        `activity/calendar?month=${this.activityDate}`
      );
      console.log(res);
      if (res.code === 200) {
        this.activityList = res.data;
        console.log(this.activityList);
        // let day = new Date();
        this.formatter(new Date());
      }
    },
    selectchangeDate(date) {
      this.selectDate = this.moment(date).format("YYYY-MM-DD");
      console.log(this.selectDate);
      this.getActivity();
    },
    async getActivity() {
      const res = await this.axios.get(
        `activity?type=0&limit=1000&page=1&status=0&history=0&name=&date=${this.selectDate}&tema_id=`
      );
      if (res.code === 200) {
        console.log(res, "活动列表");
        const { list, meta } = res.data;
        this.list = list;
      }
    },
    toActivityInfo(item) {
      this.$router.push({ path: `/activityDetails?id=${item.id}` });
    },
  },
};
</script>



<style lang="less" scoped>
.calendar {
  position: relative;
  padding-top: 30px;
  .bg-img {
    position: absolute;
    top: 0;
  }
  .calendar_box {
    padding-top: 30px;
    position: relative;
    width: 92%;
    margin: auto;
    border-radius: 20px;
    overflow: hidden;
    background: #fff;
    /deep/.van-calendar__bottom-info {
      color: #ba282f;
      font-size: 25px;
    }
    /deep/.van-calendar__month-title {
      display: none;
    }
    .calendar-top {
      height: 60px;
      font-size: 27px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 40px;
      padding-right: 40px;
      margin-bottom: 10px;
      .select {
        display: flex;
        align-items: center;
        position: relative;
        z-index: 999;
        background: #fff;
        padding-left: 30px;
        p {
          margin-right: 10px;
        }
      }
    }
  }
  .calendar-popup {
    height: 100vh;
    width: 100vw;
    position: absolute;
    left: 0;
    top: 0;
    background: transparent;
    // background: rgba(0, 0, 0, 0.5);
    z-index: 99;
    /deep/.van-picker-column__item {
      font-size: 14px;
    }
    .van-picker {
      width: 139px;
      position: absolute;
      left: 50%;
      top: 4%;
      transform: translate(-50%);
      box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.09);
    }
  }
  .activity_lis {
    width: 92%;
    margin: auto;
    padding-top: 38px;
    .title {
      position: relative;
      padding-left: 14px;
      p {
        font-size: 28px;
      }
      .icon-box {
        width: 34px;
        height: 34px;
        background: linear-gradient(
          142deg,
          #f26a52 0%,
          rgba(255, 185, 162, 0) 100%
        );
        border-radius: 50%;
        position: absolute;
        left: 0;
      }
    }
    ul {
      margin-top: 19px;
      li {
        padding: 39px 10px 31px 32px;
        background: #fff;
        border-radius: 18px;
        margin-bottom: 30px;
        .top_box {
          display: flex;
          align-items: center;
          .yuan {
            width: 18px;
            height: 18px;
            border: 3px solid #be2d32;
            border-radius: 50%;
            margin-right: 17px;
          }
          .p1 {
            font-size: 24px;
            color: #a0a0a0;
          }
        }
        .p2 {
          font-size: 28px;
          margin-top: 20px;
        }
      }
    }
  }
}
</style>